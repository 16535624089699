import './theme/styles/App.css';
import './theme/styles/commonStyle/mapComponent.css';
import '@fontsource/inter';
import Provider from '../src/contexts/SnackbarProvider';
// routes
import Router from './routes';
// hooks
import useStore from './contexts/AuthProvider';
// components
import LoadingScreen from './components/LoadingScreen';
import SocketContextProvider from './contexts/socketProvide';
import { ThemeProvider } from '@emotion/react';
import { theme } from './utils/theme';
import UserActivitySocketManager from './components/chat/UserActivitySocketManager';
// import PrivacyPolicy from './pages/dashboard/PrivacyPolicy';
import { Provider as StoreProvider } from "react-redux";
import { store } from './redux/store';
// ----------------------------------------------------------------------

export default function App() {
  const { isInitialized } = useStore();

  return (
    <>
      <ThemeProvider theme={theme}>
      <StoreProvider store={store}>
        <Provider>
          <SocketContextProvider>
            {isInitialized ? <Router /> : <LoadingScreen />}
            <UserActivitySocketManager />
          </SocketContextProvider>
        </Provider>
        </StoreProvider>
      </ThemeProvider>
    </>
  );
}
