import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Use localStorage for web
import reducer from "./reducers";

const appReducer = combineReducers(reducer);

const rootReducer = (state, action) => {
  /**
   * Reset store state on logout action
   */
  return appReducer(state, action);
};

// Persist configuration
const persistConfig = {
  key: "root",
  storage, // localStorage for web
  whitelist: ["user"], // Add reducers you want to persist
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

const persistor = persistStore(store);

export { store, persistor };
