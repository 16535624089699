import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthProvider';
import ClientProvider from './react-query';
import 'normalize.css';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import PrivacyPolicy from './pages/dashboard/PrivacyPolicy';
import Sample from './pages/Sample';

if (
  localStorage.getItem('i18nextLng') === '' ||
  localStorage.getItem('i18nextLng') == null
) {
  localStorage.setItem('i18nextLng', 'en');
}
var selectLanguage = localStorage.getItem('i18nextLng');
i18n.changeLanguage(selectLanguage);

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <ClientProvider>
      <BrowserRouter language={selectLanguage}>
        <AuthProvider>
          <App />
        </AuthProvider>
      </BrowserRouter>
      {/* <ReactQueryDevtools/> */}
    </ClientProvider>
  </I18nextProvider>,
  document.getElementById('root')
);
