import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
// hooks
// routes
import { PATH_AUTH } from '../routes/paths';
import useStore from '../contexts/AuthProvider';
import { UserRole } from 'src/utils/enum';
import { useState } from 'react';
import { useSelector } from 'react-redux';
// import { Login } from '@mui/icons-material';

// ----------------------------------------------------------------------

GuestGuardRole.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuardRole({ children }) {
  // const isAuthenticated = useStore((state) => state.isAuthenticated);
  const role = useStore((state) => state.role);
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  const accessToken = useSelector((state) => state.user.accessToken);


  if (!accessToken && !role) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }

    return <Navigate to={PATH_AUTH.login} replace />;
  }

  if (role === UserRole.GUEST || accessToken) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <>{children}</>;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
