import create from 'zustand';
import { devtools } from 'zustand/middleware';
import produce from 'immer';
import { convType, MessageStatusEnum } from 'src/utils/enum';

const socketStore = create(
  devtools((set, get) => ({
    isLoadingConversation: false,
    isLoadingChats: false, // Loading status of chat messages
    conversation: {}, // current conversation data
    chatList: [],
    businessConv: true,
    setConversation: (payload) => {
      // Update the conversation
      set((state) => ({ ...state, conversation: payload }));
    },
    setIsLoadingConversation: (payload) => {
      // Update the conversation loading status
      set((state) => ({ ...state, isLoadingConversation: payload }));
    },
    setIsLoadingChats: (payload) => {
      // Update the chats loading status
      set((state) => ({ ...state, isLoadingChats: payload }));
    },
    setBusinessConv: (payload) => {
      set(
        produce((draft) => {
          draft.businessConv = payload;
        }),
        false,
        'businessConv/set'
      );
    },
    emptyConversation: false,

    setChatList: (payload) => {
      set(
        produce((draft) => {
          draft.chatList = payload;
          draft.emptyConversation = !payload.length;
          // Sort chatList by timestamp
          draft.chatList.sort(
            (x, y) =>
              new Date(y?.msgData.timeStamp) - new Date(x?.msgData.timeStamp)
          );
        }),
        false,
        'message/setChatList'
      );

      return get().filterChatListByTimeStamp(); // Consider if this call is necessary here
    },

    pushChatList: (payload) => {
      set(
        produce((draft) => {
          draft.chatList.push(...payload);
        }),
        false,
        'message/pushChatList'
      );
      return get().filterChatListByTimeStamp();
    },
    removeChatList: ({ userId, chatId, groupId }) => {
      set(
        produce((draft) => {
          let chatIndex = -1;
          if (chatId !== undefined) {
            chatIndex = draft.chatList.findIndex((el) => el.chatId === chatId);

            // If there no such chat, remove the chat by matching the userId
            if (chatIndex === -1) {
              chatIndex = draft.chatList.findIndex(
                (el) => el?.user?.userId === userId
              );
            }
          } else if (userId !== undefined) {
            chatIndex = draft.chatList.findIndex(
              (el) => el?.user?.userId === userId
            );
          } else if (groupId !== undefined) {
            chatIndex = draft.chatList.findIndex(
              (el) => el?.groupId === groupId
            );
          }
          if (chatIndex !== -1) {
            draft.chatList.splice(chatIndex, 1);
          } else {
          }
        }),
        false,
        'message/removeChatList'
      );
    },

    updateChatListWithMessages: (messages) => {
      set(
        produce((draft) => {
          draft.chatList = [...draft.chatList, ...messages];
        }),
        false,
        'message/updateChatListWithMessages'
      );
    },

    replaceConvInChatList: (chatId, employeeId) =>
      set(
        produce((draft) => {
          let chat;
          chat = draft.chatList.find((el) => el.chatId === chatId);
          if (chat) chat.employee.push(employeeId);
        }),
        false,
        'message/replaceConvInChatList'
      ),
    filterChatListByTimeStamp: () =>
      set(
        produce((draft) => {
          draft.chatList.sort(
            (x, y) =>
              new Date(y?.msgData.timeStamp) - new Date(x?.msgData.timeStamp)
          );
          draft.emptyConversation = !get().chatList.length;
        }),
        false,
        'message/filterChatList'
      ),
    setOnlineUser: ({ receiverId, status }) =>
      set(
        produce((draft) => {
          let chat = draft.chatList.find(
            (el) => el?.user?.userId === receiverId
          );
          if (chat) chat.user.isOnline = status;
          if (
            get().interactionUser.userId === receiverId &&
            get().interactionUser.type === convType.USER
          ) {
            draft.interactionUser.isOnline = status;
          }
        }),
        false,
        'message/setOnlineUser'
      ),
    setTypingUser: ({ receiverId, typing, type }) =>
      set(
        produce((draft) => {
          let chat = draft.chatList.find(
            // eslint-disable-next-line eqeqeq
            (el) => el?.user?.userId === receiverId && el?.user?.type == type
          );
          if (chat && chat.user) chat.user.isTyping = typing;
          if (draft.interactionUser.userId === receiverId) {
            draft.interactionUser.isTyping = typing;
          } else {
            draft.interactionUser.isTyping = 0;
          }
        }),
        false,
        'message/setTypingUser'
      ),
    checkUserExist: (id, type) => {
      get(
        produce((draft) => {
          if (type === 'user') {
            let chat = draft.chatList.find((el) => el?.user?.userId === id);
            return !!chat;
          }
        }),
        false,
        'message/checkForUserOrBusiness'
      );
    },
    appendUserInChatList: (payload, userCheck = false) =>
      set(
        produce((draft) => {
          if (userCheck) {
            let chat = draft.chatList.find(
              (el) => el?.user?.userId === payload.msgData.receiverId
            );
            if (!chat) draft.chatList.unshift(payload);
          } else {
            let chat = draft.chatList.find(
              (el) => el?.chatId === payload.chatId
            );
            if (!chat) draft.chatList.unshift(payload);
          }
        }),
        false,
        'message/appendUserOrBusiness'
      ),
    updateMessageStatusInChatList: ({ userId, status, chatId }) =>
      set(
        produce((draft) => {
          let chat;
          if (chatId) chat = draft.chatList.find((el) => el.chatId === chatId);
          if (userId)
            chat = draft.chatList.find((el) => el?.user?.userId === userId);
          if (chat && chat.msgData) chat.msgData.readStatus = status;
        }),
        false,
        'message/updateStatusForAll'
      ),
    updateUnreadCountInChatList: ({ chatId, receiverId, action }) =>
      set(
        produce((draft) => {
          let chat;
          if (chatId) chat = draft.chatList.find((el) => el.chatId === chatId);
          if (receiverId)
            chat = draft.chatList.find((el) => el?.user?.userId === receiverId);
          if (chat && action === 'plus')
            chat.unreadCount = chat.unreadCount + 1;
          if (chat && action === 'readAll') chat.unreadCount = 0;
        }),
        false,
        'message/updateUnreadCountForAll'
      ),
    replaceMessageInChatList: ({
      msgData,
      replaceId,
      unreadCount,
      profileId,
    }) => {
      set(
        produce((draft) => {
          const chat = draft.chatList.find(
            (el) => el?.user?.userId === replaceId
          );
          if (chat) {
            chat.msgData = msgData;
            if (unreadCount) chat.unreadCount = unreadCount;
          } else if (profileId && msgData?.receiverId === profileId) {
            draft.chatList.unshift({
              convType: 1,
              user: {
                userId: msgData?.senderId,
                image: msgData?.userPhoto,
                name: msgData?.userName,
                isOnline: true,
              },
              unreadCount: 0,
              msgData,
            });
          }
        }),
        false,
        'message/replace'
      );
      get().filterChatListByTimeStamp();
    },
    replaceMessageInChatListByChatId: ({ msgData, chatId, unreadCount }) => {
      set(
        produce((draft) => {
          const chat = draft.chatList.find((el) => el.chatId === chatId);
          if (chat) {
            chat.msgData = msgData;
            if (unreadCount) chat.unreadCount = unreadCount;
          }
        }),
        false,
        'message/replace/chatId'
      );
      return get().filterChatListByTimeStamp();
    },
    //current chat with
    interactionUser: {
      name: '',
      image: '',
      userId: '',
      businessId: '',
      chatId: '',
      type: 0,
      isTyping: 0,
      isOnline: 0,
      isActive: '',
      geoTag: '',
    },
    setInteraction: (payload) => {
      set(
        produce((draft) => {
          draft.interactionUser = payload;
        }),
        false,
        'interactionUser/set'
      );
    },
    updateInteractionGroupInfo: (
      { name, image, description, geoTag, isPrivate, groupId, isActive },
      currentInteract = true
    ) => {
      set(
        produce((draft) => {
          const chat = draft.chatList.find(
            (el) => el?.group?.groupId === groupId
          );
          if (name) {
            if (currentInteract) draft.interactionUser.name = name;
            if (chat) chat.group.name = name;
          }
          if (image) {
            if (currentInteract) draft.interactionUser.image = image;
            if (chat) chat.group.image = image;
          }

          if (description) {
            if (currentInteract)
              draft.interactionUser.description = description;
            if (chat) chat.group.description = description;
          }
          if (geoTag) {
            if (currentInteract) draft.interactionUser.geoTag = geoTag;
            if (chat) chat.group.geoTag = geoTag;
          }
          if (currentInteract) draft.interactionUser.isActive = isActive;
          if (chat) chat.group.isActive = isActive;

          if (currentInteract) draft.interactionUser.isPrivate = isPrivate;
          if (chat) chat.group.isPrivate = isPrivate;
        }),
        false,
        'interactionUser/updateGroupInfo'
      );
    },
    //chat logs
    chatLogs: [],
    chatLogHasMoreData: false,
    emptyChat: false,
    // openChat: false,
    closeChat: false,

    setCloseConversation: (ack) => {
      set(
        produce((draft) => {
          draft.closeChat = ack; // Set based on the success parameter
        }),
        false,
        'message/setCloseConversation'
      );
    },
    setOpenConversation: (ack) => {
      set(
        produce((draft) => {
          if (ack) {
            draft.closeChat = ack;
          }
        }),
        false,
        'message/setCloseConversation'
      );
    },
    setChatLogs: (payload, currentChatUser, lastId) => {
      set(
        produce((draft) => {
          draft.interactionUser.userId = currentChatUser;
          if (lastId && payload.length) {
            draft.chatLogs.unshift(...payload);
          } else {
            draft.chatLogs = payload;
          }
          draft.emptyChat = !payload.length;
          draft.chatLogHasMoreData = payload.length === 15 ? true : false;
        }),
        false,
        'message/setChatLogs'
      );
    },
    businessChatLogs: [],
    setBusinessChatLogs: (payload, chatId, lastId) => {
      set(
        produce((draft) => {
          if (chatId) draft.interactionUser.chatId = chatId;
          if (lastId && payload.length) {
            draft.chatLogs.unshift(...payload);
          } else {
            draft.chatLogs = payload;
          }
          draft.emptyChat = !payload.length;
          draft.chatLogHasMoreData = payload.length === 15;
        }),
        false,
        'message/setBusinessAndGroupChatLogs'
      );
    },
    businessConversationData: {},
    setbusinessConversationData: (payload) =>
      set(
        produce((draft) => {
          draft.businessConversationData = payload;
        }),
        false,
        'messaage/businessConversationData'
      ),

    appendChatLogs: (payload) =>
      set(
        produce((draft) => {
          draft.chatLogs.push(payload); // replace push insted of unshift
          if (get().emptyChat) draft.emptyChat = false;
        }),
        false,
        'messaage/appendChatLogs'
      ),
    replaceMessageInChatLogs: (payload) =>
      set(
        produce((draft) => {
          const updatedMsg = draft.chatLogs.find(
            (el) => el?.mId === payload.mId
          );
          if (updatedMsg) {
            updatedMsg.message = payload.message;
            // If the message is updated, set isEdited to true
            updatedMsg.isEdited = true;
          }
          const chatLastMsg = draft.chatList.find(
            (el) => el?.msgData?.mId === payload.mId
          );
          if (chatLastMsg) chatLastMsg.msgData.message = payload.message;
        }),
        false,
        'messaage/replaceChatLogs'
      ),
    replaceMessageReactionInChatLogs: (payload) =>
      set(
        produce((draft) => {
          const updatedMsg = draft.chatLogs.find(
            (el) => el?.mId === payload.mId
          );
          if (updatedMsg) updatedMsg.reaction = payload.reaction;
        }),
        false,
        'messaage/replaceChatLogsInReaction'
      ),
    //handle individual and business same time
    updateMessageStatusOfChatLogs: ({
      to,
      mId,
      event,
      chatId,
      type,
      from,
      messageHistory,
      ...other
    }) => {
      let status, updateChatId, updateUserId;
      let currentDate = new Date().toUTCString();
      set(
        produce((draft) => {
          const currentType = get().interactionUser.type;
          let historyObjRead = {
            readMessageType: 3,
            messageStatusTime: currentDate,
          };
          let historyObjdelivered = {
            readMessageType: 2,
            messageStatusTime: currentDate,
          };
          switch (event) {
            case 'delivered':
              if (
                parseInt(type) === currentType &&
                from === get().interactionUser.userId
              ) {
                const messageToDeliver = draft.chatLogs.find(
                  (el) =>
                    el?.mId === mId && el?.readStatus < MessageStatusEnum.READ
                );
                if (messageToDeliver) {
                  messageToDeliver.readStatus = MessageStatusEnum.DELIVERED;
                  messageToDeliver.messageHistory.push(historyObjdelivered);
                  updateUserId = from;
                }
              }
              if (
                parseInt(type) === currentType &&
                [
                  get().interactionUser?.businessId,
                  get().interactionUser?.businessUserId,
                ].includes(from)
              ) {
                const messageToUpdate = draft.chatLogs.find(
                  (el) =>
                    el?.mId === mId && el?.readStatus < MessageStatusEnum.READ
                );
                if (messageToUpdate) {
                  messageToUpdate.readStatus = MessageStatusEnum.DELIVERED;
                  messageToUpdate.messageHistory =
                    messageToUpdate.messageHistory || []; // Initialize messageHistory as an array if it's undefined
                  messageToUpdate.messageHistory.push(historyObjdelivered);
                }
                updateChatId = chatId;
              }
              status = MessageStatusEnum.DELIVERED;
              break;
            case 'readMessage':
              if (
                parseInt(type) === currentType &&
                from === get().interactionUser.userId
              ) {
                const messageIndex = draft.chatLogs.findIndex(
                  (el) => el.mId === mId
                );
                if (messageIndex !== -1) {
                  draft.chatLogs[messageIndex].readStatus =
                    MessageStatusEnum.READ;
                  draft.chatLogs[messageIndex].messageHistory.push(
                    historyObjRead
                  );
                  updateUserId = from;
                }
              }
              if (
                parseInt(type) === currentType &&
                [
                  get().interactionUser?.businessId,
                  get().interactionUser?.businessUserId,
                ].includes(from)
              ) {
                const messageToUpdate = draft.chatLogs.find(
                  (el) => el.mId === mId
                );
                if (messageToUpdate) {
                  messageToUpdate.readStatus = MessageStatusEnum.READ;
                  messageToUpdate.messageHistory =
                    messageToUpdate.messageHistory || []; // Initialize messageHistory as an array if it's undefined
                  messageToUpdate.messageHistory.push(historyObjRead);
                }
                updateChatId = chatId;
              }

              status = MessageStatusEnum.READ;
              break;
            case 'readAllMessages':
              if (
                parseInt(type) === currentType &&
                from === get().interactionUser.userId
              ) {
                draft.chatLogs = draft.chatLogs.map((item) => {
                  if (item.readStatus !== MessageStatusEnum.READ) {
                    return {
                      ...item,
                      readStatus: MessageStatusEnum.READ,
                      // Fixed the page breaking issue
                      messageHistory: [
                        ...(item?.messageHistory || []),
                        historyObjRead,
                      ],
                    };
                  }
                  return item;
                });
                updateUserId = from;
              }
              if (
                parseInt(type) === currentType &&
                [
                  get().interactionUser?.businessId,
                  get().interactionUser?.businessUserId,
                ].includes(from)
              ) {
                draft.chatLogs = draft.chatLogs.map((item) => {
                  if (item.readStatus !== MessageStatusEnum.READ) {
                    // Ensure that messageHistory exists and is an array
                    const updatedMessageHistory = Array.isArray(
                      item.messageHistory
                    )
                      ? [...item.messageHistory, historyObjRead]
                      : [historyObjRead];
                    return {
                      ...item,
                      readStatus: MessageStatusEnum.READ,
                      messageHistory: updatedMessageHistory,
                    };
                  }
                  return item;
                });
                updateChatId = chatId;
              }

              status = MessageStatusEnum.READ;
              break;

            // case 'readMessages':
            //   if (
            //     parseInt(type) === currentType &&
            //     from === get().interactionUser.userId
            //   ) {
            //     const messageIndex = draft.chatLogs.findIndex(
            //       (el) => el.mId === mId
            //     );
            //     if (messageIndex !== -1) {
            //       draft.chatLogs[messageIndex].readStatus =
            //         MessageStatusEnum.READ;
            //       draft.chatLogs[messageIndex].messageHistory.push(
            //         historyObjRead
            //       );
            //       updateUserId = from;
            //     }
            //   }
            //   if (
            //     parseInt(type) === currentType &&
            //     [
            //       get().interactionUser?.businessId,
            //       get().interactionUser?.businessUserId,
            //     ].includes(from)
            //   ) {
            //     const messageToUpdate = draft.chatLogs.find(
            //       (el) => el.mId === mId
            //     );
            //     if (messageToUpdate) {
            //       messageToUpdate.readStatus = MessageStatusEnum.READ;
            //       messageToUpdate.messageHistory =
            //         messageToUpdate.messageHistory || [];
            //       messageToUpdate.messageHistory.push(historyObjRead);
            //     }
            //     updateChatId = chatId;
            //   }

            //   status = MessageStatusEnum.READ;
            //   break
            default:
              break;
          }
        }),
        false,
        'message/individual message update'
      );
      get().updateMessageStatusInChatList({
        userId: updateUserId,
        status,
        chatId: updateChatId,
      });
    },
    //handle action of message - forward | edit | delete
    forwardMessage: {},
    setForwardMessage: (payload, chatId, lastId) => {
      set(
        produce((draft) => {
          draft.forwardMessage = payload;
        }),
        false,
        'message/setForwardMessage'
      );
    },
    editMessage: {},
    setEditMessage: (payload, chatId, lastId) => {
      set(
        produce((draft) => {
          draft.editMessage = payload;
        }),
        false,
        'message/setEditMessage'
      );
    },
    reactionMessage: {},
    setReactionMessage: (payload, chatId, lastId) => {
      set(
        produce((draft) => {
          draft.reactionMessage = payload;
        }),
        false,
        'message/setReactionMessage'
      );
    },
    replaceDeleteMessage: ({ mId, frontStatus }) => {
      set(
        produce((draft) => {
          const updatedMsg = draft.chatLogs.find((el) => el?.mId === mId);
          if (updatedMsg) updatedMsg.deletedStatus = frontStatus;
          const chatLastMsg = draft.chatList.find(
            (el) => el?.msgData?.mId === mId
          );
          if (chatLastMsg) chatLastMsg.msgData.deletedStatus = frontStatus;
        }),
        false,
        'messaage/updateDeleteMsg'
      );
    },
    messageData: null,

    setNewMessageRecive: (id, type) => {
      set(
        produce((draft) => {
          draft.messageData = { id, type }
        }),
        true,
        'message/setNewMessageRecive'
      );
    }
  }))
);

export default socketStore;
