import { createSlice } from "@reduxjs/toolkit";
// import { IAppReducer } from "../../../utils/types";

// user reducer store user data and all types of token
const initialState = {
  isSkip: false,
  configurableFieldData: null,
  businessProfileData: null
};

const AppReducer = createSlice({
  name: "app",
  initialState,
  reducers: {
    setIsSkip: (state, action) => {
      state.isSkip = action.payload;
    },
    setConfigurableFieldData: (state, action) => {
      state.configurableFieldData = action.payload;
    },
    setBusinessProfileData: (state, action) => {
      state.businessProfileData = action.payload;
    },
  },
});

export const {
  setIsSkip,
  setConfigurableFieldData,
  setBusinessProfileData
} = AppReducer.actions;

export default AppReducer.reducer;
