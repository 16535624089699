import React from 'react';
import { Avatar, Box, Button, ListItem, ListItemAvatar } from '@mui/material';
import { USER_API_URL } from 'src/api/axios';
import useAxiosPrivate from 'src/hooks/useAxiosPrivate';
import { useMutation, useQueryClient } from 'react-query';
import UserAvatar from '../common/UserAvatar';
import files from 'src/helpers/helpers';
import { withTranslation } from 'react-i18next';

const RequestRow = (props) => {
  const { name, userPic, requestId, userName, isVerify, showVerify } = props;
  const axiosPrivate = useAxiosPrivate();
  const queryClient = useQueryClient();



  const { mutateAsync: friendRequest } = useMutation(
    async (data) => {
      if (data) {
        return await axiosPrivate.put(USER_API_URL.friendRequest + requestId);
      } else {
        return await axiosPrivate.delete(
          USER_API_URL.friendRequest + requestId
        );
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['friendList']);
      },
    }
  );

  const rejected = async () => {

    if (!isVerify) {
      if (showVerify) {
        showVerify()
      }
    } else {
      await friendRequest(false);
    }


  };

  const accepted = async () => {
    if (!isVerify) {
      if (showVerify) {
        showVerify()
      }
    } else {
      await friendRequest(true);
    }
  };

  return (
    <ListItem className="px-2 bg-list-item d-flex align-items-start flex-sm-nowrap flex-wrap mb-1">
      <ListItemAvatar className="linkList_avatar_container">
        <Avatar>
          <UserAvatar
            diameter={40}
            imgSrc={files(userPic, 'image')}
            alternateSrc={name}
          />
        </Avatar>
      </ListItemAvatar>
      <Box sx={{ width: '60%' }} className="d-flex flex-column">
        <h4 className="app_text_14_500">{name}</h4>
        <div className="d-flex">
          {userName && (
            <span className="app_text_gray app_text_12">{`@${userName}`}</span>
          )}
        </div>
      </Box>
      <div className="d-flex flex-nowrap ms-auto">
        <Button
          className={`linkList_btn app_border_primary app_text_transform app_text_14_semibold app_text_primary app_bg_primary_light`}
          onClick={rejected}
          variant="outlined"
        >
          {props.t('popup_btn.reject')}
        </Button>
        <Button
          className={`linkList_btn app_text_transform app_text_14_semibold text-white app_bg_primary ms-2`}
          onClick={accepted}
          variant="contained"
        >
          {props.t('popup_btn.accept')}
        </Button>
      </div>
    </ListItem>
  );
};

export default withTranslation()(RequestRow);
