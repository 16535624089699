import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import { withTranslation } from 'react-i18next';
import useAxiosPrivate from 'src/hooks/useAxiosPrivate';
import { AUTH_API_URL } from 'src/api/axios';
import { Device } from 'src/utils/enum';

const CompleteVerificationDialog = (props) => {
    const axiosPrivate = useAxiosPrivate();
    const { onClose, open, email, onSuccess } = props;
    const { enqueueSnackbar } = useSnackbar();

    const { mutateAsync: verifyEmail, isLoading } = useMutation(
        async (email) => {
            const payload = {
                userName: { "2": email },
                device: Device.WEB,
            };
            const response = await axiosPrivate.post(AUTH_API_URL.getOtp, payload);
            return response.data;
        },
        {
            onSuccess: () => {
                enqueueSnackbar(props.t('toast_message.otp_success'), {
                    variant: 'success',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    autoHideDuration: 2000,
                });
                onSuccess && onSuccess();
            },
            onError: () => {
                enqueueSnackbar(props.t('toast_message.verification_failed'), {
                    variant: 'error',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    autoHideDuration: 2000,
                });
            },
        }
    );

    const handleVerifyClick = async () => {
        try {
            await verifyEmail(email);
        } catch (error) {
            console.error('Verification failed:', error);
        }
    };

    return (
        <>
            <Dialog onClose={onClose} open={open} maxWidth="xs">
                <DialogTitle sx={{ textAlign: 'center', position: 'relative' }}>
                    <span className="app_text_transform">
                        {props.t('popup_title.complete_verification')}
                    </span>
                    <IconButton
                        onClick={onClose}
                        size="small"
                        aria-label="close"
                        sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            color: 'gray',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <p className="app_text_16 app_text_gray px-4 app_text_transform">
                    {props.t('popup_title.title_description')}
                </p>
                <div className="d-flex align-items-center p-3 justify-content-center">
                    <Button
                        onClick={handleVerifyClick}
                        variant="contained"
                        className="app_bg_primary app_text_16_semibold app_btn_lg text-white app_text_transform ms-3 link"
                        disabled={isLoading}
                    >
                        {/* {props.t('popup_btn.save')} */}
                        verify email
                    </Button>
                </div>
            </Dialog>
        </>
    )
}

export default withTranslation()(CompleteVerificationDialog)