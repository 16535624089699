import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import OTPInputMask from "src/components/authentication/OTPInputMask";
import LoadingButton from "@mui/lab/LoadingButton";
import CountDownTimer from "src/components/common/CountDownTimer";
import { useSnackbar } from "notistack";
import useAxiosPrivate from "src/hooks/useAxiosPrivate";
import { useMutation } from "react-query";
import { AUTH_API_URL } from "src/api/axios";
import { Device } from "src/utils/enum";


const VerifyOTPDialog = (props) => {
    const axiosPrivate = useAxiosPrivate(); // Hook to make authenticated API calls
    const { onClose, open, email, onVerify, userId } = props;
    const { enqueueSnackbar } = useSnackbar();
    const [showSubmitBtn, setShowSubmitBtn] = useState(false);
    const [enteredOTP, setEnteredOTP] = useState({});
    const [resendRequest, setResendRequest] = useState(false);
    const [otpString, setOtpString] = useState('');
    const [message, setMessage] = useState('');
    const storageData = JSON.parse(localStorage.getItem('auth-storage'))


    const { mutateAsync: signupVerifyOTP, isLoading } = useMutation(
        async () => {
            const payload = {
                otp: otpString,
                userName: { "2": email },
                device: Device.WEB,
            };
            const response = await axiosPrivate.post(AUTH_API_URL.signupVerifyOTP, payload);
            return response.data;
        },
        {
            onSuccess: () => {
                enqueueSnackbar(props.t('toast_message.verify_otp'), {
                    variant: 'success',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    autoHideDuration: 2000,
                });
                updateIsVerifyInState(1)
                console.log('Verified SuccessFully');
                onClose();
                onVerify();
            },
            onError: (error) => {
                enqueueSnackbar(
                    props.t('toast_message.invalid_otp') || error.message,
                    {
                        variant: 'error',
                        anchorOrigin: { vertical: 'top', horizontal: 'right' },
                        autoHideDuration: 2000,
                    }
                );
                // onClose();
                console.error("Verification Error:", error);
            },
        }
    );



    const { mutateAsync: resendVerifyOTP } = useMutation(async (event) => {
        event.preventDefault();
        setResendRequest(false)
        const payload = {
            userName: { "2": email },
            device: 3,
            reason: 'forSignUp',
        }
        const res = await axiosPrivate.post(AUTH_API_URL.resendOTP, payload);
        // setMessage(res.data?.message)
        return res.data;
    },
        {
            onSuccess: (message) => {
                enqueueSnackbar(props.t('toast_message.resend_otp_successfully') || message, {
                    variant: 'success',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    autoHideDuration: 2000,
                });
                // onClose();
                // onVerify();
            },
            onError: (error) => {
                enqueueSnackbar(
                    props.t('toast_message.invalid_otp') || error.message,
                    {
                        variant: 'error',
                        anchorOrigin: { vertical: 'top', horizontal: 'right' },
                        autoHideDuration: 2000,
                    }
                );
                // onClose();
                console.error("Verification Error:", error);
            },
        }
    );



    const resendOtp = async (event) => {
        try {
            await resendVerifyOTP(event)
        } catch (error) {
            console.error("Failed to verify OTP:", error);
        }
    }


    const handleVerifyClick = async () => {
        try {
            await signupVerifyOTP();
        } catch (error) {
            console.error("Failed to verify OTP:", error);
        }
    };

    useEffect(() => {
        const otpKeys = Object.keys(enteredOTP).filter((key) =>
            key.startsWith("otpVerify")
        );
        if (otpKeys.length === 6) {
            setOtpString(otpKeys.map((key) => enteredOTP[key]).join(''));
            setShowSubmitBtn(true);
        } else {
            setShowSubmitBtn(false);
        }
    }, [enteredOTP]);

    const handleUserOtpOnChange = (obj) => {
        setEnteredOTP(obj);
    };


    const updateIsVerifyInState = (newValue) => {
        // Retrieve the storage item
        const storageData = localStorage.getItem("auth-storage");
      
        if (storageData) {
          try {
            // Parse the JSON string to an object
            const parsedData = JSON.parse(storageData);
      
            // Safely navigate and update the nested `isVerify` key inside `state`
            if (parsedData.state) {
              parsedData.state.isVerify = newValue;
            } else {
              console.warn("State object not found in storage data.");
            }
      
            // Save the updated object back into localStorage
            localStorage.setItem("auth-storage", JSON.stringify(parsedData));
      
            console.log("Updated isVerify in state successfully:", parsedData);
          } catch (error) {
            console.error("Failed to parse or update localStorage data:", error);
          }
        } else {
          console.warn("No data found in localStorage under 'auth-storage'.");
        }
      };

    useEffect(() => {
        if (!resendRequest) {
            setMessage('')
        }
    }, [resendRequest])


    return (
        <>
            <Dialog onClose={onClose} open={open} maxWidth="xs">
                <DialogTitle className="text-center app_text_transform">
                    {/* {props.t('popup_title.verify_otp')} */}
                    {props.t('otp_verification_password_module.title')}
                </DialogTitle>
                <DialogContent>
                    <p className="app_text_16 app_text_gray px-4 app_text_transform">
                        {/* Please verify your email to access these features. */}
                        {`${props.t('otp_verification_password_module.instruction')} ${email}`}
                    </p>
                    <OTPInputMask
                        names={'otpVerify'}
                        otpOnChangeFunc={handleUserOtpOnChange}
                    />
                    {!resendRequest ? (
                        <CountDownTimer
                            hoursMinSecs={{ seconds: 60 }}
                            resendRequest={resendRequest}
                            setResendRequest={setResendRequest}
                        />
                    ) : (
                        <Box onClick={resendOtp} sx={{ cursor: 'pointer' }}>
                            <Typography
                                className="app_text_primary ms-2 text-decoration-underline app_text_14"
                            >
                                {props.t('comman_label.resend_code')}
                            </Typography>
                        </Box>
                    )}
                    {message ? (
                        <>
                            <Typography variant="body2">
                                {message}
                            </Typography>
                        </>
                    ) : (<></>)}
                </DialogContent>
                <div className="d-flex align-items-center p-3 justify-content-center">
                    {showSubmitBtn && (
                        <LoadingButton
                            fullWidth
                            variant="contained"
                            loading={isLoading}
                            loadingIndicator={props.t('comman_label.loading')}
                            className={`app_text_transform ${!isLoading ? 'app_bg_primary' : ''
                                } app_text_16_semibold app_btn_lg`}
                            onClick={handleVerifyClick}
                        >
                            {props.t('comman_label.verify_code')}
                        </LoadingButton>
                    )}
                </div>
            </Dialog>
        </>
    );
};

export default withTranslation()(VerifyOTPDialog);