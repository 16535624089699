import { Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useState } from 'react';

const CountDownTimer = ({
  hoursMinSecs,
  link,
  resendRequest,
  setResendRequest,
}) => {
  const { hours = 0, minutes = 0, seconds = 60 } = hoursMinSecs;
  const [[hrs, mins, secs], setTime] = React.useState([
    hours,
    minutes,
    seconds,
  ]);

  const tick = () => {
    if (hrs === 0 && mins === 0 && secs === 0) {
      setResendRequest(true);
    } else if (mins === 0 && secs === 0) {
      setTime([hrs - 1, 59, 59]);
    } else if (secs === 0) {
      setTime([hrs, mins - 1, 59]);
    } else {
      setTime([hrs, mins, secs - 1]);
    }
  };

  const resetTimer = () =>
    setTime([parseInt(hours), parseInt(minutes), parseInt(seconds)]);
  useEffect(() => {
    if (resendRequest) {
      resetTimer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resendRequest]);

  React.useEffect(() => {
    const timerId = setInterval(() => tick(), 1000);
    return () => clearInterval(timerId);
  });

  return (
    <Typography paragraph mb={0}>
      you can resend after{' '}
      {`${mins.toString().padStart(2, '0')}:${secs
        .toString()
        .padStart(2, '0')}`}
    </Typography>
  );
};

export default CountDownTimer;
