import React, { useRef, useState } from 'react';
import {
  Backdrop,
  Box,
  Button,
  List,
  Popover,
  Typography,
} from '@mui/material';
import MapSearchbar from './MapSearchbar';
import { CHAT_API_URL } from 'src/api/axios';
import { useQuery } from 'react-query';
import { useEffect } from 'react';
import MapFeedItem from './MapFeedItem';
import DashboardSidebar from '../layouts/DashboardSidebar';
import ArrowLeftRoundedIcon from '@mui/icons-material/ArrowLeftRounded';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import AppTooltip from '../common/AppTooltip';
import useAxiosPrivate from 'src/hooks/useAxiosPrivate';
import { useQueryClient } from 'react-query';
import { authStore } from 'src/contexts/AuthProvider';
import { withTranslation } from 'react-i18next';
import EventFeedItem from './EventFeedItem';
import MapEventDetailSidebar from './MapEventDetailSidebar';

const MapSidebar = (props) => {
  const {
    handleDirection,
    userLocationData,
    handleSearchUser,
    handleFiltering,
    userLocation,
    handleResetFiltering,
    filteredData,
    hanldeViewProfile,
    userEventAllData,
    eventFilteredData,
    categoryData,
    handleViewProfileClose,
  } = props;

  console.log("DEBUG userLocationData", userLocationData)

  const [selectedFeed, setSelectedFeed] = useState();
  const [showSidebar, setShowSidebar] = useState(false);
  const [showDashboardSidebar, setShowDashboardSidebar] = useState(false);
  const [activeFiltering, setActiveFiltering] = useState('');
  const [eventDataId, setEventDataId] = useState('');
  // anchorEl for event details popover
  const [anchorEl, setAnchorEl] = useState(null);
  const sidebarContentBoxRef = useRef(null);
  const sidebarHeaderRef = useRef(null);
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate();

  const { _id: profileId } = authStore((state) => state.user);

  // Checks if the event details popover is open
  const open = Boolean(anchorEl);

  const openDashboardOnClick = () => {
    setShowDashboardSidebar(true);
    queryClient.invalidateQueries(['userProfileSidebar']);
  };
  const closeDashboardOnClick = () => {
    setShowDashboardSidebar(false);
  };
  const handleSidebar = () => {
    setShowSidebar(true);
  };
  useEffect(() => {
    const scrollEvent = () => {
      if (sidebarContentBoxRef.current.scrollTop > 10) {
        sidebarContentBoxRef.current.classList.add('scrolling');
        sidebarHeaderRef.current.classList.add('shadow');
      } else {
        sidebarContentBoxRef.current.classList.remove('scrolling');
        sidebarHeaderRef.current.classList.remove('shadow');
      }
    };
    const scrollConst = sidebarContentBoxRef?.current;
    scrollConst.addEventListener('scroll', scrollEvent);
    return () => {
      scrollConst.removeEventListener('scroll', scrollEvent);
    };
  }, []);

  const handleListItemClick = (index) => {
    setSelectedFeed(index);
  };

  const handleActiveFiltering = (flag) => {
    setActiveFiltering(flag);
    if (!flag) {
      handleResetFiltering();
      userLocation();
      queryClient.invalidateQueries(['userEventAllData']);
    }
  };

  // Close the event details popover
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEventDetailOnEventFeedClick = (event, eventId) => {
    setEventDataId(eventId);
    setAnchorEl(event.currentTarget);
    handleViewProfileClose();
  };
  const handleEventDetailOnCloseClick = () => {
    handleClose();
  };

  const userProfileViewClick = (...args) => {
    hanldeViewProfile(...args);
    handleEventDetailOnCloseClick();
  };

  useEffect(() => {
    handleViewProfileClose();
    handleClose();
  }, [categoryData]);

  const { data: fetchChatCategory, refetch: fetcCat } = useQuery(
    'chatCatList',
    async ({ signal }) => {
      try {
        const response = await axiosPrivate.get(CHAT_API_URL.chatCategory, {
          signal,
        });
        return response.data;
      } catch (error) {
        console.error('Error fetching chat categories:', error);
        return [];
      }
      // return await axiosPrivate.get(CHAT_API_URL.chatCategory, { signal }).then((res) => res.data);

      // return [];
    },
    { refetchOnWindowFocus: false }
  );

  return (
    <>
      <div className="position-absolute top-0 left-0 w-100">
        <Box ref={sidebarHeaderRef} className="sidebar_header">
          <MapSearchbar
            handleSidebar={handleSidebar}
            onSidebarBtnClick={openDashboardOnClick}
            handleSearchUser={handleSearchUser}
            handleFiltering={handleFiltering}
            userLocation={userLocation}
            handleActiveFiltering={handleActiveFiltering}
          />
        </Box>
        <div className={`map_sidebar_wrapper ${showSidebar ? '' : 'hide'}`}>
          <div ref={sidebarContentBoxRef} className="sidebar_content_box">
            <Box className="map_sidebar_feed_box">
              <List>
                {!activeFiltering && filteredData.length === 0 ? (
                  userLocationData && userLocationData.length > 0 ? (
                    userLocationData.map((item, i) => {
                      return (
                        <MapFeedItem
                          key={i}
                          handleViewProfile={userProfileViewClick}
                          feedData={item}
                          onFeedSelect={handleListItemClick}
                          selectedFeed={selectedFeed}
                          handleDirection={() => handleDirection(item)}
                          profileId={profileId}
                          chatCategory={fetchChatCategory}
                        />
                      );
                    })
                  ) : (
                    <>
                      <Typography sx={{ margin: '8px' }}>
                        record not found
                      </Typography>
                    </>
                  )
                ) : filteredData && filteredData.length > 0 ? (
                  filteredData.map((item, i) => {
                    return (
                      <MapFeedItem
                        key={i}
                        feedData={item}
                        handleViewProfile={userProfileViewClick}
                        onFeedSelect={handleListItemClick}
                        selectedFeed={selectedFeed}
                        handleDirection={() => handleDirection(item)}
                        profileId={profileId}
                        chatCategory={fetchChatCategory}
                      />
                    );
                  })
                ) : (
                  <>
                    <Typography sx={{ margin: '8px' }}>
                      record not found
                    </Typography>
                  </>
                )}
              </List>
              {!activeFiltering && eventFilteredData?.length === 0
                ? userEventAllData?.length > 0 &&
                  userEventAllData?.map((event, index) => (
                    <List
                      disablePadding
                      className="event_feed_list"
                      key={index}
                    >
                      <EventFeedItem
                        eventData={event}
                        onClickFunc={handleEventDetailOnEventFeedClick}
                      />
                    </List>
                  ))
                : eventFilteredData?.length > 0 &&
                  eventFilteredData?.map((event, index) => (
                    <List
                      disablePadding
                      className="event_feed_list"
                      key={index}
                    >
                      <EventFeedItem
                        eventData={event}
                        onClickFunc={handleEventDetailOnEventFeedClick}
                      />
                    </List>
                  ))}
            </Box>
          </div>
          <AppTooltip
            title={
              !showSidebar
                ? props.t('tooltip_text.expand_panel')
                : props.t('tooltip_text.collapse_panel')
            }
            placement={'right'}
          >
            <Button
              onClick={() => setShowSidebar(!showSidebar)}
              className="map_sidebar_wrapper_expand_btn"
            >
              {showSidebar ? (
                <ArrowLeftRoundedIcon style={{ color: '#70757a' }} />
              ) : (
                <ArrowRightRoundedIcon style={{ color: '#70757a' }} />
              )}
            </Button>
          </AppTooltip>
        </div>
        <div
          className={`map_dashboard_sidebar_wrapper  ${
            showDashboardSidebar ? 'show' : ''
          }`}
        >
          <DashboardSidebar
            onClose={closeDashboardOnClick}
            showDashboardSidebar={showDashboardSidebar}
          />
        </div>
        {showDashboardSidebar && (
          <Backdrop
            open={showDashboardSidebar}
            className="dashboard_sidebar_backdrop"
            onClick={() => setShowDashboardSidebar(false)}
          ></Backdrop>
        )}
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          PaperProps={{
            style: {
              minHeight: '500px',
              maxHeight: '500px',
            },
          }}
          sx={{
            '& .MuiPaper-root': {
              borderRadius: '16px !important',
              maxWidth: { xsm: 410, xs: 300 },
              width: { xsm: 'calc(100% - 42px)', xs: 'calc(100% - 10px)' },
              marginLeft: { xsm: '42px', xs: '10px' },
            },
          }}
        >
          <MapEventDetailSidebar
            eventDataId={eventDataId}
            onCloseFunc={handleEventDetailOnCloseClick}
          />
        </Popover>
      </div>
    </>
  );
};

// export default MapSidebar
export default withTranslation()(React.memo(MapSidebar));
