import { Box, Typography } from '@mui/material';
import { withTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { axiosPrivate, USER_API_URL } from 'src/api/axios';
import LoadingScreen from 'src/components/LoadingScreen';



const PrivacyPolicy = () => {

    const { data: privacyData, isLoading } = useQuery(
        ['privacyData'],
        async ({ signal }) => {
            return await axiosPrivate
                .get(USER_API_URL.getPrivacyTerms, { signal })
                .then((res) => res?.data);
        },
    );


    return (
        <>
            {isLoading ? <LoadingScreen /> :
                <Box sx={{ padding: '25px' }}>
                    <Box sx={{ widht: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px 0px' }}>
                        <Typography variant='h5'>Privacy Policy</Typography>
                    </Box>
                    <div
                        style={{
                            border: "1px solid #ccc",
                            padding: "10px",
                            borderRadius: "4px",
                            minHeight: "100%",
                            backgroundColor: '#f7f7f7'
                        }}
                        dangerouslySetInnerHTML={{ __html: privacyData?.privacy_policy }} // Inject saved HTML content
                    />
                </Box>
            }
        </>
    )
}

export default withTranslation()(PrivacyPolicy);