import React from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import DomainIcon from '@mui/icons-material/Domain';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import FilterIcon from '@mui/icons-material/Filter';
import { withTranslation } from 'react-i18next';

const MapCategories = (props) => {
  const {
    categoryList,
    selectedMapOption,
    mapCatUpdateFunc,
  } = props;

  // Combine the category list and predefined categories for easier index mapping
  const categories = [
    { _id: 'all', icon: <DoneAllIcon />, label: props.t('home_module.map_cat_all') },
    { _id: 'people', icon: <PersonIcon />, label: props.t('home_module.map_cat_people') },
    { _id: 'group', icon: <GroupIcon />, label: props.t('home_module.map_cat_group') },
    ...categoryList.map(cate => ({
      _id: cate._id,
      icon: <DomainIcon />,
      label: cate.name,
    })),
    { _id: 'event', icon: <EventAvailableOutlinedIcon />, label: props.t('home_module.map_cat_events') },
    { _id: 'posts', icon: <FilterIcon />, label: props.t('home_module.map_cat_posts') }
  ];

  // Find the index of the selected tab
  const selectedIndex = categories.findIndex(category => category._id === selectedMapOption);

  return (
    <Box className="map_category_wrapper">
      <div className="home_map_options_wrapper">
        <Box
          sx={{
            maxWidth: { xs: 400, md: 500, lg: 800 },
            bgcolor: 'transparent',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Tabs
            sx={{
              alignItems: 'center',
              '& .MuiTabs-flexContainer': {
                alignItems: 'center',
                padding: '8px 0',
              },
              '& .MuiTabs-scroller': { display: 'flex' },
              '& .MuiTabScrollButton-root': {
                bgcolor: '#fff',
                width: '40px',
                height: '40px',
                borderRadius: '50%',
              },
            }}
            variant="scrollable"
            scrollButtons="auto"
            TabIndicatorProps={{ sx: { display: 'none' } }}
            value={selectedIndex} // Set the value to the selected index
          >
            {categories.map((category, index) => (
              <Tab
                key={`${Math.random().toString(36).substring(2, 9)}_catBtn${index}`}
                className={`home_map_btn app_text_14_semibold ${selectedMapOption === category._id ? 'mapActive' : ''}`}
                onClick={() => mapCatUpdateFunc(category._id)}
                icon={React.cloneElement(category.icon, { color: selectedMapOption === category._id ? '#fff' : '#626B76' })}
                iconPosition="start"
                label={category.label}
              />
            ))}
          </Tabs>
        </Box>
      </div>
    </Box>
  );
};

export default withTranslation()(MapCategories);
