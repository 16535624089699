import React, { useState, useCallback, memo } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Close from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
} from '@mui/material';
import ExchangeCircle from 'src/svgComponents/ExchangeCircle';
import UserAvatar from '../common/UserAvatar';
import { UserRole } from 'src/utils/enum';
import { withTranslation } from 'react-i18next';

// Extracted to a separate component for better reusability and performance
const BusinessListItem = memo(({ business, isSelected, onSelect, files, t }) => (
  <ListItemButton
    sx={{ px: 1, py: 2, justifyContent: 'space-between' }}
    onClick={() => onSelect(business)}
  >
    <div className="app_text_14_semibold d-flex align-items-center justify-content-start">
      <UserAvatar
        diameter={30}
        imgSrc={files(business.image, 'image')}
        alternateSrc={business.name[0]}
      />
      <h4 className="app_text_14_500 text-white mb-0 ms-2 text-start text-capitalize text_limit_180">
        {business.name}
      </h4>
    </div>
    {isSelected && (
      <span>
        <CheckCircleIcon style={{ fontSize: 24, color: '#fff' }} />
      </span>
    )}
  </ListItemButton>
));

BusinessListItem.displayName = 'BusinessListItem';

const ResponsiveMenu = memo((props) => {
  const {
    respoMenu,
    onRespoMenuClose,
    storeData,
    files,
    handleLogout,
    role,
    userDataFunc,
    businessListData,
    t
  } = props;

  const [expanded, setExpanded] = useState(false);

  const handleChange = useCallback((panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  }, []);

  const handleUserDataUpdate = useCallback((business) => {
    userDataFunc(business);
    onRespoMenuClose();
    setTimeout(() => handleChange('panel1')(), 100);
  }, [userDataFunc, onRespoMenuClose, handleChange]);

  const handleLogoutClick = useCallback(() => {
    handleLogout();
    onRespoMenuClose();
  }, [handleLogout, onRespoMenuClose]);

  const userDisplayName = storeData?.user?.displayName || storeData?.user?.userName;
  const userInitial = userDisplayName ? userDisplayName[0] : null;

  return (
    <Drawer anchor="right" open={respoMenu} onClose={onRespoMenuClose}>
      <Box className="responsiveMenu_wrapper">
        <div className="respo_menu_header">
          <div className="d-flex">
            <UserAvatar
              diameter={50}
              imgSrc={files(storeData?.user?.profilePic, 'image')}
              alternateSrc={userInitial}
            />
            <div className={`d-flex flex-column ms-2 ${storeData?.user?.email ? '' : 'justify-content-center'}`}>
              <span className={`text-white ${storeData?.user?.email ? 'app_text_14_500' : 'app_text_16_500'}`}>
                {userDisplayName}
              </span>
              {storeData?.user?.email && (
                <span className="app_text_14 text-white">
                  {storeData.user.email}
                </span>
              )}
            </div>
          </div>
          <IconButton onClick={onRespoMenuClose}>
            <Close style={{ fontSize: 30, color: '#fff' }} />
          </IconButton>
        </div>

        <div className="mt-4">
          {role !== UserRole.GUEST && (
            <Accordion
              className="respoMenu_accordian"
              expanded={expanded === 'panel1'}
              onChange={handleChange('panel1')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <ListItemIcon>
                  <ExchangeCircle size={30} />
                </ListItemIcon>
                <span className="app_text_16_500 text-white d-flex align-items-center app_text_transform">
                  {t('main_navbar_text.switch_account')}
                </span>
              </AccordionSummary>
              <AccordionDetails sx={{ bgcolor: '#3a2364a1' }}>
                <h4 className="app_text_14_500 text-white app_text_transform">
                  {t('main_navbar_text.personal_profile')}
                </h4>
                <Button
                  fullWidth
                  variant="text"
                  className="app_text_14_semibold d-flex align-items-center justify-content-between ps-2 pt-3 pb-2"
                  onClick={() => handleUserDataUpdate()}
                >
                  <div className="app_text_14_semibold d-flex align-items-center justify-content-start">
                    <UserAvatar
                      diameter={30}
                      imgSrc={files(storeData?.user?.profilePic, 'image')}
                      alternateSrc={userDisplayName}
                    />
                    <h4 className="app_text_14_500 text-white mb-0 ms-2 text-capitalize text-start text_limit_120">
                      {userDisplayName}
                    </h4>
                  </div>
                  {!storeData?.businessId && (
                    <CheckCircleIcon style={{ fontSize: 24, color: '#fff' }} />
                  )}
                </Button>
                <Divider className="my-2 mb-1" style={{ borderColor: '#000' }} />
                <h4 className="app_text_14_500 text-white mt-3 app_text_transform">
                  {t('main_navbar_text.business_profile')}
                </h4>
                <List>
                  {businessListData?.length ? (
                    businessListData.map((business) => (
                      <BusinessListItem
                        key={business._id}
                        business={business}
                        isSelected={storeData?.businessId === business._id}
                        onSelect={handleUserDataUpdate}
                        files={files}
                        t={t}
                      />
                    ))
                  ) : (
                    <li className="app_text_12_semibold text-white py-2 d-flex justify-content-center app_text_transform">
                      {t('main_navbar_text.no_account')}
                    </li>
                  )}
                </List>
              </AccordionDetails>
            </Accordion>
          )}

          <List className="respoMenu_list">
            <ListItemButton onClick={handleLogoutClick}>
              <ListItemIcon>
                <LogoutOutlinedIcon style={{ color: '#fff', fontSize: 30 }} />
              </ListItemIcon>
              <span className="app_text_16_500 text-white d-flex align-items-center app_text_transform">
                {t('main_navbar_text.logout')}
              </span>
            </ListItemButton>
          </List>
        </div>
      </Box>
    </Drawer>
  );
});

ResponsiveMenu.displayName = 'ResponsiveMenu';

export default withTranslation()(ResponsiveMenu);