import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import {
  Button,
  DialogActions,
  DialogContent,
  InputBase,
  Paper,
} from '@mui/material';
import SearchIcon from 'src/svgComponents/SearchIcon';
import UserLinkListRow from './UserLinkListRow';
import AppTooltip from '../common/AppTooltip';
import { Stack } from '@mui/system';
import UnlinkUserPopup from './UnlinkUserPopup';
import _ from 'lodash';
import { INDIVIDUAL_API_URL } from 'src/api/axios';
import { useQuery } from 'react-query';
import useAxiosPrivate from 'src/hooks/useAxiosPrivate';
import { withTranslation } from 'react-i18next';
import { authStore } from 'src/contexts/AuthProvider';
import { UserRole } from 'src/utils/enum';

const UserLinkListPopup = (props) => {
  const { onClose, open, userLinkListData, userProfileData, businessId } =
    props;
  const [unlinkStart, setUnlinkStart] = useState(false);
  const [unlinkConfimPopup, setUnlinkConfimPopup] = useState(false);
  const [unlinkChecked, setUnlinkChecked] = useState([]);
  const [unlickableData, setUnLinkableData] = useState([]);
  const [userUnlinkApiObj, setUserUnlinkApiObj] = useState([]);
  const [searchData, setSerchData] = useState('');
  const role = authStore((state) => state.role);
  // const axiosPrivate = useAxiosPrivate();
  // eslint-disable-next-line no-unused-vars
  // const { data: userProfileLinkList, refetch } = useQuery(
  //   'userProfileLinkList',
  //   async ({ signal }) => {
  //     return await axiosPrivate
  //       .get(INDIVIDUAL_API_URL.profile, { signal })
  //       .then((res) => res.data);
  //   },
  //   { refetchOnWindowFocus: false }
  // );
  const handleClose = () => {
    onClose();
    setUnlinkChecked([]);
    setUnlinkStart(false);
  };
  const handleSearch = (e) => {
    setSerchData(e.target.value.trim());
  };
  const handleListOnUnLinkClick = () => {
    setUnlinkStart(true);
  };

  const handleUnlinkToggle = (value) => {
    const currentIndex = unlinkChecked.indexOf(value);
    const newChecked = [...unlinkChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setUnlinkChecked(newChecked);
  };

  const handleUnlinkingOnCancel = () => {
    setUnlinkChecked([]);
    setUnlinkStart(false);
  };

  useEffect(() => {
    let updatedUserLinkReqData = userLinkListData?.filter(
      (user) => user?.friendStatus === 3
    );
    const userFilterData = updatedUserLinkReqData?.filter(
      (user) => Object.keys(user).length !== 0
    );
    let uniqueData = userFilterData?.filter(
      (v, i, a) => a.findIndex((t) => t.id === v.id) === i
    );
    setUnLinkableData(uniqueData);
  }, [userLinkListData]);

  const handleUnlinkingOnSelectAll = () => {
    let unlinkUserIdArr = [];
    unlickableData.map((user) => unlinkUserIdArr.push(user.id));
    setUnlinkChecked(unlinkUserIdArr);
  };
  const unlinkConfimPopupClose = () => {
    setUnlinkConfimPopup(false);
  };
  useEffect(() => {
    if (unlinkChecked) {
      const userAllPbj = [];
      unlinkChecked.forEach((userID) => {
        userLinkListData
          .filter((user) => user.id === userID)
          .forEach((user) => {
            let obj = { userId: user.id };
            if (!_.isEmpty(user.businessId)) {
              obj.businessId = user.businessId;
            }
            userAllPbj.push(obj);
          });
      });
      setUserUnlinkApiObj(userAllPbj);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unlinkChecked]);
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      className="request_popup_wrapper linked_list_popup"
    >
      <DialogTitle className="px-3 pt-0">
        <span className="app_text_20_500 app_text_transform">
          {props.t('popup_title.linked_list')}
        </span>
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 12,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      {userLinkListData && userLinkListData.length > 0 ? (
        <DialogContent className="px-0 pb-0">
          <Paper className="linking_popup_searchbox mx-3">
            <IconButton style={{ marginTop: '-2px' }}>
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder={props.t('comman_label.search')}
              onChange={handleSearch}
            />
          </Paper>
          {!unlinkStart ? (
            <div className="mt-3 px-3 d-flex justify-content-between align-items-center">
              <span className="app_text_gray app_text_14">
                {props.t('popup_message.linked_contacts')}
              </span>
              <AppTooltip title={props.t('tooltip_text.unlink_contacts')}>
                {/* <Button sx={{ minWidth: 'unset', height: '24px', width: '24px', backgroundColor: '#6200ee', "&:hover": { backgroundColor: '#6200ee' } }} className='app_blank_btn' ><LinkOffIcon style={{ color: '#fff', fontSize: 16, transform: 'rotate(-45deg)' }} /></Button> */}
                {role === UserRole.USER ? (
                  <Button
                    onClick={() => handleListOnUnLinkClick()}
                    startIcon={
                      <LinkOffIcon style={{ color: '#fff', fontSize: 16 }} />
                    }
                    sx={{
                      minWidth: 'unset',
                      backgroundColor: '#6200ee',
                      '&:hover': { backgroundColor: '#6200ee' },
                      px: 2,
                    }}
                    className="app_text_transform text-white app_blank_btn"
                  >
                    {props.t('popup_btn.unlink_contacts')}
                  </Button>
                ) : (
                  <></>
                )}
              </AppTooltip>
            </div>
          ) : (
            <div className="mt-3 px-3 d-flex justify-content-between align-items-center">
              <span className="app_text_14_semibold app_text_primary">{`unlink user (${unlinkChecked.length})`}</span>
              <Stack direction={'row'} spacing={2}>
                <Button
                  onClick={() => handleUnlinkingOnCancel()}
                  className="app_text_transform app_text_primary app_blank_btn pe-0"
                  sx={{
                    minWidth: 'unset',
                    '&:hover': { backgroundColor: 'transparent' },
                  }}
                >
                  {props.t('popup_btn.cancel')}
                </Button>
                <Button
                  onClick={() => handleUnlinkingOnSelectAll()}
                  className="app_text_transform app_text_primary app_blank_btn pe-0"
                  sx={{
                    minWidth: 'unset',
                    '&:hover': { backgroundColor: 'transparent' },
                  }}
                >
                  {props.t('popup_btn.select_all')}
                </Button>
              </Stack>
            </div>
          )}

          <List className="linkList mt-3" sx={{ pt: 0 }}>
            {userLinkListData
              ?.filter(
                (user) =>
                  user?.businessId !== businessId && user.friendStatus === 3
              )
              .filter((user) =>
                user?.name.toLowerCase().includes(searchData?.toLowerCase())
              )
              .map((user, index) => (
                <UserLinkListRow
                  key={index}
                  srno={index}
                  requestId={user.requestId}
                  userId={user.id}
                  name={user.name}
                  businessName={user.businessName}
                  businessImage={user.businessImage}
                  businessLocation={user.businessLocation}
                  businessOwner={user.businessOwner}
                  userName={user.userName}
                  userPic={user.image}
                  mobile={user.mobile}
                  permissions={user.permissions}
                  friendStatus={user.friendStatus}
                  contact={user.contact}
                  address={user.address}
                  businessId={user.businessId}
                  unlinkStart={unlinkStart}
                  unLinkCheckFunc={handleUnlinkToggle}
                  unlinkChecked={unlinkChecked}
                  userPermission={userProfileData?.permissions}
                  onClose={onClose}
                />
              ))}
          </List>
        </DialogContent>
      ) : (
        <DialogContent className="d-flex justify-content-center align-items-center py-2">
          <span className="app_text_16_semibold app_text_transform app_text_gray">
            {props.t('popup_message.no_link_user')}
          </span>
        </DialogContent>
      )}
      {unlinkChecked.length > 0 && (
        <DialogActions sx={{ py: 1.5 }}>
          <Button
            onClick={() => setUnlinkConfimPopup(true)}
            fullWidth
            className="app_bg_primary text-white"
          >
            {props.t('popup_btn.unlink')}
          </Button>
        </DialogActions>
      )}
      <UnlinkUserPopup
        unnlikCancleFun={handleUnlinkingOnCancel}
        unlinkUserApiObj={userUnlinkApiObj}
        open={unlinkConfimPopup}
        onClose={unlinkConfimPopupClose}
      />
    </Dialog>
  );
};

UserLinkListPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default withTranslation()(UserLinkListPopup);
