import React, { useEffect, useState } from 'react';
import { Stack, TextField, Box } from '@mui/material';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  fieldset: {
    background: '#F2E9FF',
    outline: '1px solid #6200ee',
  },
}));
const OTPInputMask = ({ otpOnChangeFunc, names }) => {
  const objs = {};
  objs[`${names}-1`] = '';
  objs[`${names}-2`] = '';
  objs[`${names}-3`] = '';
  objs[`${names}-4`] = '';
  objs[`${names}-5`] = '';
  objs[`${names}-6`] = '';
  const obj = {};
  obj[`${names}-1`] = false;
  obj[`${names}-2`] = false;
  obj[`${names}-3`] = false;
  obj[`${names}-4`] = false;
  obj[`${names}-5`] = false;
  obj[`${names}-6`] = false;

  const [otpInput, setOtpInput] = useState(objs);
  const [isOtpFilled, setIsOtpFilled] = useState(obj);
  const classes = useStyles();
  const textFieldStyleProps = {
    variant: 'outlined',
    label: false,
    InputLabelProps: { shrink: false },
  };
  const digit1FilledInputStyle = {
    InputProps: {
      className: isOtpFilled[`${names}-1`] ? classes.fieldset : '',
    },
  };
  const digit2FilledInputStyle = {
    InputProps: {
      className: isOtpFilled[`${names}-2`] ? classes.fieldset : '',
    },
  };
  const digit3FilledInputStyle = {
    InputProps: {
      className: isOtpFilled[`${names}-3`] ? classes.fieldset : '',
    },
  };
  const digit4FilledInputStyle = {
    InputProps: {
      className: isOtpFilled[`${names}-4`] ? classes.fieldset : '',
    },
  };
  const digit5FilledInputStyle = {
    InputProps: {
      className: isOtpFilled[`${names}-5`] ? classes.fieldset : '',
    },
  };
  const digit6FilledInputStyle = {
    InputProps: {
      className: isOtpFilled[`${names}-6`] ? classes.fieldset : '',
    },
  };

  const handleOnChange = (ev) => {
    const { value, name } = ev.target;
    setOtpInput({ ...otpInput, [name]: value });
    setIsOtpFilled({ ...isOtpFilled, [name]: value.length === 1 });
    const [, fieldIndex] = name.split('-');
    let fieldIntIndex = parseInt(fieldIndex, 10);

    // check if all fields are not filled
    if (
      Object.keys(isOtpFilled).filter((digit) => isOtpFilled[digit]).length !==
      6
    ) {
      // Check if no of char in field == maxlength
      if (value.length >= 1) {
        if (fieldIntIndex < 6) {
          // Get the next input field using it's name
          const nextfield = document.querySelector(
            `input[name=${names}-${fieldIntIndex + 1}]`
          );
          // If found, focus the next field
          if (nextfield !== null) {
            nextfield.focus();
          }
        }
      } else {
        if (fieldIntIndex > 1) {
          const prevField = document.querySelector(
            `input[name=${names}-${fieldIntIndex - 1}]`
          );
          if (prevField !== null) {
            prevField.focus();
          }
        }
      }
    }
  };
  const onKeyDown = (ev) => {
    const { value, name } = ev.target;
    const [, fieldIndex] = name.split('-');
    let fieldIntIndex = parseInt(fieldIndex, 10);
    let key = ev.keyCode || ev.charCode;

    if (key === 8 || key === 46) {
      if (value === '') {
        if (fieldIntIndex > 1) {
          const prevField = document.querySelector(
            `input[name=${names}-${fieldIntIndex - 1}]`
          );
          if (prevField !== null) {
            prevField.focus();
          }
        }
      }
    }
  };

  useEffect(() => {
    otpOnChangeFunc(otpInput);
  }, [otpInput, otpOnChangeFunc]);

  return (
    <>
      <Box>
        <Stack
          className=""
          direction="row"
          spacing={{ xts: 2, xs: 1 }}
          sx={{
            '& .MuiInputBase-input': {
              fontSize: { xts: '22px !important', xs: '16px !important' },
              color: '#6200ee',
              textAlign: 'center',
              padding: { xts: '16.5px 14px', xs: '8px' },
            },
          }}
        >
          <NumberFormat
            name={`${names}-1`}
            onKeyDown={onKeyDown}
            onChange={handleOnChange}
            customInput={TextField}
            {...textFieldStyleProps}
            {...digit1FilledInputStyle}
            format="#"
          />
          <NumberFormat
            name={`${names}-2`}
            onKeyDown={onKeyDown}
            onChange={handleOnChange}
            customInput={TextField}
            {...textFieldStyleProps}
            {...digit2FilledInputStyle}
            format="#"
          />
          <NumberFormat
            name={`${names}-3`}
            onKeyDown={onKeyDown}
            onChange={handleOnChange}
            customInput={TextField}
            {...textFieldStyleProps}
            {...digit3FilledInputStyle}
            format="#"
          />
          <NumberFormat
            name={`${names}-4`}
            onKeyDown={onKeyDown}
            onChange={handleOnChange}
            customInput={TextField}
            {...textFieldStyleProps}
            {...digit4FilledInputStyle}
            format="#"
          />
          <NumberFormat
            name={`${names}-5`}
            onKeyDown={onKeyDown}
            onChange={handleOnChange}
            customInput={TextField}
            {...textFieldStyleProps}
            {...digit5FilledInputStyle}
            format="#"
          />
          <NumberFormat
            name={`${names}-6`}
            onKeyDown={onKeyDown}
            onChange={handleOnChange}
            customInput={TextField}
            {...textFieldStyleProps}
            {...digit6FilledInputStyle}
            format="#"
          />
        </Stack>
      </Box>
    </>
  );
};

OTPInputMask.propTypes = {
  otpOnChangeFunc: PropTypes.func,
};

export default OTPInputMask;
