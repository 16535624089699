import { useEffect } from 'react';
import create from 'zustand';
import createContext from 'zustand/context';
import { devtools, persist } from 'zustand/middleware';
import axios, { AUTH_API_URL } from '../api/axios';
import { UserRole } from 'src/utils/enum';
const { Provider, useStore } = createContext();

const middleware_ = (f) =>
  create(devtools(persist(f, { name: 'auth-storage' })));
// const middleware_ = (f) => create(devtools(f, { name: "auth-storage" }));
const authStore = middleware_((set, get) => ({
  isAuthenticated: false,
  isInitialized: false,
  user: {},
  method: 'jwt',
  accessToken: '',
  role: null,
  isProfileComplete: '',
  pofileCompleteMessge: '',
  isGuestContinue: false,
  setIsGuestContinue: (value) => set({ isGuestContinue: value }),
  businessId: '',
  isRefreshing: false,
  hasBusiness: false,
  hasBusinessListApi: false,
  setHasBusiness: (param) => set({ hasBusiness: param }),
  setHasBusinessListApi: (param) => set({ hasBusinessListApi: param }),
  setInitialized: () => set({ isInitialized: true }),
  setAccessToken: (data) => set({ accessToken: data }),
  updateUserPermissions: (newPermissions) => {
    set((state) => {
      const updatedUser = {
        ...state.user,
        permissions: {
          ...state.user.permissions,
          ...newPermissions,
        },
      };
      return { user: updatedUser };
    });
  },
  login: async (loginObj) => {
    try {
      const response = await axios.post(
        AUTH_API_URL.login,
        JSON.stringify(loginObj),
        {
          headers: { 'Content-Type': 'application/json', env: 'test' },
          withCredentials: true,
        }
      );
      const verify = response?.data?.isVerify;
      const isDeleted = response?.data?.user?.isDeleted;
      if (verify === 0) {
        return [response?.data, null];
      } else if (isDeleted === 1) {
        return [response?.data, null];
      } else {
        set({
          user: response?.data?.user,
          isVerify: response?.data?.isVerify,
          isProfileComplete: response?.data?.isProfileComplete,
          pofileCompleteMessge: response?.data?.message,
          accessToken: response?.data?.accessToken,
          role: UserRole.USER,
          isAuthenticated: true,
        });
        return [response?.data, null];
      }
    } catch (err) {
      if (!err?.response) {
        return [null, 'No Server Response'];
      } else if (err?.response?.data?.errors) {
        return [null, Object.values(err.response.data.errors)[0]];
      } else if (err?.response?.data?.message) {
        return [null, err.response.data.message];
      } else {
        return [null, 'Login Failed'];
      }
    }
  },
  // Paasing the 'setIsLoading' method to set the loading flag when user login
  newLogin: async (loginObjNew, setIsLoading = () => { }) => {
    try {
      // Set the loader on click of login
      setIsLoading(true);
      const response = await axios.post(
        AUTH_API_URL.loginNew,
        JSON.stringify(loginObjNew),
        {
          headers: { 'Content-Type': 'application/json', env: 'test' },
          withCredentials: true,
        }
      );
      // Unset the loader after finishing the API call
      setIsLoading(false);
      const verify = response?.data?.isVerify;
      const isDeleted = response?.data?.user?.isDeleted;
  
      if (verify === 0) {
        set({
          user: response?.data?.user,
          isVerify: response?.data?.isVerify,
          isProfileComplete: response?.data?.isProfileComplete,
          profileCompleteMessage: response?.data?.message,
          accessToken: response?.data?.accessToken || null,
          role: UserRole.USER,
          isAuthenticated: true, 
        });
        return [response?.data, null];
      } else if (isDeleted === 1) {
        return [response?.data, null];
      } else {
        set({
          user: response?.data?.user,
          isVerify: response?.data?.isVerify,
          isProfileComplete: response?.data?.isProfileComplete,
          profileCompleteMessage: response?.data?.message,
          accessToken: response?.data?.accessToken,
          role: UserRole.USER,
          isAuthenticated: true,
        });
        return [response?.data, null];
      }
    } catch (err) {
      setIsLoading(false); // Ensure loader is turned off even on error
      if (!err?.response) {
        return [null, 'No Server Response'];
      } else if (err?.response?.data?.errors) {
        return [null, Object.values(err.response.data.errors)[0]];
      } else if (err?.response?.data?.message) {
        return [null, err.response.data.message];
      } else {
        return [null, 'Login Failed'];
      }
    }
  },
  
  guestLogin: async (loginObj) => {
    try {
      const response = await axios.post(
        AUTH_API_URL.guestLogin,
        JSON.stringify(loginObj),
        {
          headers: { 'Content-Type': 'application/json', env: 'test' },
          withCredentials: true,
        }
      );

      const isGuest = response?.data?.isGuest;
      if (!isGuest) {
        return [response?.data, null];
      } else {
        set({
          user: {
            displayName: 'Guest',
            userName: 'Guest user',
            _id: '',
            email: '',
            mobile: '',
            profilePic: '',
          },
          isVerify: response?.data?.isVerify,
          accessToken: response?.data?.accessToken,
          role: UserRole.GUEST,
          isAuthenticated: false,
        });
        return [response?.data, null];
      }
    } catch (err) {
      if (!err?.response) {
        return [null, 'No Server Response'];
      } else if (err.response?.status === 400) {
        return [null, 'Missing user or Password'];
      } else if (err.response?.status === 409) {
        return [null, 'Unauthorized'];
      } else {
        return [null, 'Login Failed'];
      }
    }
  },
  register: async (signUpObj) => {
    try {
      const response = await axios.post(
        AUTH_API_URL.register,
        JSON.stringify(signUpObj),
        {
          headers: { 'Content-Type': 'application/json', env: 'test' },
          withCredentials: true,
        }
      );
      set({
        // user: {},
        // accessToken: '',
        // role: null,
        user: response?.data?.user,
        accessToken: response?.data?.accessToken,
        role: UserRole.USER,
        verify: response?.data?.isVerify,
        isAuthenticated: true,
      });
      return [response.data.message, null];
    } catch (err) {
      return [null, err.response.data.errors];
    }
  },
  signupVerifyOTP: async (otpObj) => {
    try {
      const response = await axios.post(
        AUTH_API_URL.signupVerifyOTP,
        JSON.stringify(otpObj),
        {
          headers: { 'Content-Type': 'application/json', env: 'test' },
          withCredentials: true,
        }
      );
      set({
        // user: response?.data?.user,
        // accessToken: response?.data?.accessToken,
        // role: UserRole.USER,
        // verify: response?.data?.isVerify,
        // isAuthenticated: true,
      });
      return [response.data.message, null];
    } catch (err) {
      return [null, err.response.data.message];
    }
  },
  resendOTP: async (resendOtpObj) => {
    try {
      const response = await axios.post(
        AUTH_API_URL.resendOTP,
        JSON.stringify(resendOtpObj),
        {
          headers: { 'Content-Type': 'application/json', env: 'test' },
          withCredentials: true,
        }
      );
      return [response.data.message, null];
    } catch (err) {
      return [null, err.response];
    }
  },
  getOtp: async (getOtpObj) => {
    try {
      const response = await axios.post(
        AUTH_API_URL.getOtp,
        JSON.stringify(getOtpObj),
        {
          headers: { 'Content-Type': 'application/json', env: 'test' },
          withCredentials: true,
        }
      );
      return [response.data.message, null];
    } catch (err) {
      return [null, err.response.data];
    }
  },
  mobileExist: async (otpObj) => {
    try {
      const response = await axios.post(
        AUTH_API_URL.mobileExist,
        JSON.stringify(otpObj),
        {
          headers: { 'Content-Type': 'application/json', env: 'test' },
          withCredentials: true,
        }
      );
      return [response, null];
    } catch (err) {
      return [null, err.response];
    }
  },
  verifyOtp: async (otpObj) => {
    try {
      const response = await axios.post(
        AUTH_API_URL.verifyOtp,
        JSON.stringify(otpObj),
        {
          headers: { 'Content-Type': 'application/json', env: 'test' },
          withCredentials: true,
        }
      );
      return [response.data, null];
    } catch (err) {
      return [null, err.response.data];
    }
  },
  verifyOtpMobileFirebase: async (otpObj) => {
    try {
      const response = await axios.post(
        AUTH_API_URL.verifyOtpFirebaseMobile,
        JSON.stringify(otpObj),
        {
          headers: { 'Content-Type': 'application/json', env: 'test' },
          withCredentials: true,
        }
      );
      return [response.data, null];
    } catch (err) {
      return [null, err.response.data];
    }
  },
  forgotPassword: async (forgotPasswordObj) => {
    try {
      const response = await axios.post(
        AUTH_API_URL.forgotPassword,
        JSON.stringify(forgotPasswordObj),
        {
          headers: { 'Content-Type': 'application/json', env: 'test' },
          withCredentials: true,
        }
      );
      return [response.data, null];
    } catch (err) {
      return [null, err.response.data];
    }
  },
  logout: () => {
    set({
      user: {},
      accessToken: '',
      role: null,
      businessId: '',
      isAuthenticated: false,
      hasBusiness: false,
    });
  },
  refreshToken: async () => {
    try {
      const response = await axios.get(AUTH_API_URL.refreshToken, {
        withCredentials: true,
      });
      set({ accessToken: response?.data?.accessToken });
      return [response?.data?.accessToken, undefined];
    } catch (error) {
      return [undefined, true];
    }
  },
  updateUserProfile: (data) => {
    set({
      user: data,
    });
  },

  setBusinessId: (data) =>
    set({
      businessId: data,
    }),
  updateRole: (data) => {
    set({
      role: data,
    });
  },
}));

const AuthProvider = ({ children }) => {
  const setInitialized = authStore((state) => state.setInitialized);
  useEffect(() => {
    setInitialized();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <Provider createStore={() => authStore}>{children}</Provider>;
};

const refreshStore = create((set, get) => ({
  isRefreshing: false,
}));

export { AuthProvider, authStore, refreshStore };
export default useStore;
