import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userData: null,
  accessToken: null,
};

const UserReducer = createSlice({
  name: "user",
  initialState,
  reducers: {
    saveUserData: (state, action) => {
      state.userData = action.payload;
    },
    removeUserData: (state) => {
      state.userData = null;
      state.accessToken = null;
    },
    setAuthToken: (state, action) => {
      state.accessToken = action.payload;
    },
  },
});

export const {
  saveUserData,
  removeUserData,
  setAuthToken,
} = UserReducer.actions;

export default UserReducer.reducer;
