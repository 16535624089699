import React, { useState } from 'react';
import WelcomeIcon from 'src/svgComponents/WelcomeIcon';
import UserImagePlaceholder from '../user/UserImagePlaceholder';

const UserAvatar = ({ diameter, imgSrc, alternateSrc }) => {
  const [imageError, setImageError] = useState(false);

  const renderAvatar = () => {
    if (!imageError && imgSrc) {
      return (
        <img
          src={imgSrc}
          alt="user avatar"
          onError={() => setImageError(true)}
          style={{ width: '100%', height: '100%', borderRadius: '50%' }}
        />
      );
    }

    if (alternateSrc) {
      return (
        <UserImagePlaceholder
          diameter={diameter}
          firstChar={alternateSrc.charAt(0)}
        />
      );
    }

    return <WelcomeIcon />;
  };

  return (
    <div
      className="app_user_avatar"
      style={{
        width: `${diameter}px`,
        height: `${diameter}px`,
        borderRadius: '50%',
        overflow: 'hidden',
        transition: 'all 0.3s ease-in-out',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {renderAvatar()}
    </div>
  );
};

export default UserAvatar;
